<template>
  <tr @click="moveToPage(`/job-detail/${item.documentNo}`)">
    <td class="status">
      <span class="__ing">{{ RECRUITING_STATUS[item.recruitingStatus] }}</span>
      <i
        v-if="item.pushStatus"
        class="applyStatus"
        :class="{ disabled: !isNegoAvailable }"
        @click="callNego"
        >{{ pushStatus }}
      </i>
    </td>
    <td class="cop-name">
      {{ item.companyNm
      }}<span class="distance">{{
        `${distanceUnitBrackets(item.distance)}`
      }}</span
      ><span v-if="vipStyle" class="vip">VIP</span>
    </td>
    <td class="cop-info">
      <span :class="`${boldStyle} ${markerStyle} ${colorStyle}`">{{
        item.jobPostTitle
      }}</span>
      <!--span class="bold marker color">test</!--span-->
    </td>
    <td class="cop-area t1">
      {{ item.workingAddress }}
    </td>
    <td class="cop-price t1">{{ `${insertCommaToNumber(item.payAmt)}원` }}</td>
    <td class="cop-term t1">{{ item.workingTerm }}</td>
    <!-- <td class="cop-term t1">장기형</td> -->
  </tr>
  <!-- <tr onClick="location.href='#a'">
            <td class="status">
              <span class="__ing">모집중</span>
            </td>
            <td class="cop-name">XYZ 모텔</td>
            <td class="cop-info">성실한 룸메이드를 찾습니다</td>
            <td class="cop-area t1">경기도 부천</td>
            <td class="cop-price t1">9,000원</td>
            <td class="cop-term t1">장기형</td>
          </tr>
          <tr onClick="location.href='#a'">
            <td class="status">
              <span class="__ing">모집중</span>
            </td>
            <td class="cop-name">신라스테이</td>
            <td class="cop-info">GRAND OPEN 공채 인력 채용</td>
            <td class="cop-area t1">서울 서초구</td>
            <td class="cop-price t1">12,000원</td>
            <td class="cop-term t1">장기형</td>
          </tr>
          <tr onClick="location.href='#a'">
            <td class="status">
              <span class="__ing">모집중</span>
            </td>
            <td class="cop-name">호텔포도</td>
            <td class="cop-info">골프 매니저 모집합니다.</td>
            <td class="cop-area t1">경기도 부천</td>
            <td class="cop-price t1">120,000원</td>
            <td class="cop-term t1">단기형</td>
          </tr>
          <tr onClick="location.href='#a'">
            <td class="status">
              <span class="__ing">모집중</span>
            </td>
            <td class="cop-name">앰배서더호텔</td>
            <td class="cop-info">객실팀 하우스키핑 선임 모집합니다</td>
            <td class="cop-area t1">서울/경기/강원</td>
            <td class="cop-price t1">212,000원</td>
            <td class="cop-term t1">장기형</td>
          </tr>
          <tr onClick="location.href='#a'">
            <td class="status">
              <span class="__end">모집마감</span>
            </td>
            <td class="cop-name">롯데리조트</td>
            <td class="cop-info">골프캐디 모집합니다.[상시채용]</td>
            <td class="cop-area t1">제주</td>
            <td class="cop-price t1">302,000원</td>
            <td class="cop-term t1">단기형</td>
          </tr> -->
</template>

<script>
import { RECRUITING_STATUS } from 'Configs/registJob'
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import { postApplyJob } from 'Api/modules'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      RECRUITING_STATUS: Object.freeze(RECRUITING_STATUS),
      isNegoUpdated: false,
    }
  },
  watch: {
    documentNo() {
      this.$nextTick(function () {
        this.moveToPage()
      })
    },
  },
  computed: {
    vipStyle() {
      if (
        this.item.emphasisCodes &&
        this.item.emphasisCodes.indexOf('201') >= 0
      )
        return true
      else return false
    },
    boldStyle() {
      if (
        this.item.emphasisCodes &&
        this.item.emphasisCodes.indexOf('203') >= 0
      )
        return 'bold'
      else return ''
    },
    markerStyle() {
      if (
        this.item.emphasisCodes &&
        this.item.emphasisCodes.indexOf('204') >= 0
      )
        return 'marker'
      else return ''
    },
    colorStyle() {
      if (
        this.item.emphasisCodes &&
        this.item.emphasisCodes.indexOf('202') >= 0
      )
        return 'color'
      else return ''
    },
    pushStatus() {
      if (this.item.pushStatus) {
        //pushStatus 의도한 값이 아닌 경우 예외처리
        const pushStatusSplit = this.item.pushStatus.split('-')
        if (pushStatusSplit.length < 2) return 'a'

        const pushStatus = pushStatusSplit[1]
        if (this.item.recruitingStatus === 'CLOSE') {
          return PUSH_STATUS_NAME[pushStatus]
        } else {
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (pushStatus === 'SCALL' || pushStatus === 'SOK') {
            const targetIndex = PUSH_STATUS.findIndex(
              status => status.value === pushStatus,
            )
            //그 다음 상태를 표시
            const requestPushStatus = PUSH_STATUS[targetIndex + 1].name
            return requestPushStatus
          }
          //근무응답, 합격확인 상태는 그 상태 그대로 표시하면 된다. 버튼 역할하지 않는다
          else {
            return PUSH_STATUS_NAME[pushStatus]
          }
        }
      }
      return ''
    },
    isNegoAvailable() {
      if (this.isNegoUpdated) return false
      if (!this.item.pushStatus) return false
      if (this.item.recruitingStatus === 'CLOSE') return false

      //pushStatus 의도한 값이 아닌 경우 예외처리
      const pushStatusSplit = this.item.pushStatus.split('-')
      if (pushStatusSplit.length < 2) return false

      const pushStatus = pushStatusSplit[1]

      //근무응답, 합격확인의 경우에만 버튼으로 활성화
      if (pushStatus && (pushStatus === 'SCALL' || pushStatus === 'SOK'))
        return true
      return false
    },
  },
  methods: {
    async callNego(event) {
      event.stopPropagation()
      if (!this.isNegoAvailable) return
      if (!this.item.pushStatus) return

      //pushStatus 의도한 값이 아닌 경우 예외처리
      const pushStatusSplit = this.item.pushStatus.split('-')
      if (pushStatusSplit.length < 2) throw new Error('pushStatus invalid')

      const jobRequestId = pushStatusSplit[0]
      const senderId = this.account.userId
      const targetStatus = PUSH_STATUS.find(
        status => status.name === this.pushStatus,
      )
      const pushStatus = targetStatus.value

      try {
        await postApplyJob({ jobRequestId, senderId, pushStatus })
        this.isNegoUpdated = true //버튼비활성화
        this.$toasted.show(`${this.pushStatus} 하셨습니다.`)
      } catch (e) {
        console.error(e)
      }
      //  this.$router.go();
    },
  },
}
</script>

<style lang="scss" scoped>
.cop-area {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .tbl-jobs tr {
    position: relative;
  }
  .applyStatus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 14.66667vw;
    height: 4.53333vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2.4vw;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 2.8vw;
    font-weight: 500;
    letter-spacing: -0.08vw;
    color: #f74f11;
    font-style: normal;
    line-height: 1;
    position: absolute;
    right: 6.66667vw;
    top: 4.73333vw;
    &.disabled {
      cursor: none;
      pointer-events: none;
      color: #495052;
      &:hover {
        background-color: none;
      }
    }
    &:hover {
      background-color: rgba(#ff6831, 0.1);
    }
  }
}

@media (min-width: 1024px) {
  .applyStatus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12.5px;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #f74f11;
    font-style: normal;
    line-height: 1;
    margin: auto;
    margin-top: 10px;
    &.disabled {
      cursor: none;
      pointer-events: none;
      color: #495052;
      &:hover {
        background-color: none;
      }
    }
    &:hover {
      background-color: rgba(#ff6831, 0.1);
    }
    &.employer {
      width: 120px;
      height: 50px;
    }
  }
}
</style>
