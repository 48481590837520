<template>
  <section class="jobs-wrap normal">
    <div v-if="this.$route.name == 'jobDetail'">
      <h2>해당 업체의 다른 채용정보를 확인해보세요!</h2>
    </div>
    <div v-if="this.$route.name !== 'jobDetail'">
      <h2>일반 채용</h2>
      <select
        id="localListSelect"
        v-model="localList"
        class="select"
        @change="localListChange"
      >
        <option value="" disabled hidden>근무지</option>
        <option
          id="localList"
          v-for="(localList, idx) in LOCAL_LIST"
          :key="idx"
          :value="localList.name"
        >
          {{ localList.name }}
        </option>
      </select>
      <select
        id="jobTypeListSelect"
        v-model="jobTypeList"
        class="select"
        @change="localListChange"
      >
        <option value="">직종</option>
        <option
          id="jobtypeList"
          v-for="jobtypeList in JOB_TYPE_LIST"
          :key="jobtypeList.value"
          :value="jobtypeList.value"
        >
          {{ jobtypeList.name }}
        </option>
      </select>
    </div>
    <div class="inner">
      <table class="tbl-jobs">
        <thead>
          <tr>
            <!-- 구직자 -->
            <th v-for="colName in COLNAME_LIST" :key="colName">
              {{ colName }}
            </th>
            <!-- 구인자 -->
          </tr>
        </thead>
        <tbody>
          <normal-job-item
            v-for="(item, index) in list"
            :item="item"
            :key="index + item.documentNo"
            :companyId="companyId"
          ></normal-job-item>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import NormalJobItem from '../partials/NormalJobItem'
import { LOCAL_LIST, JOB_TYPE_LIST } from 'Configs/filter.js'

export default {
  components: {
    NormalJobItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      COLNAME_LIST: ['', '근무처', '근무내용', '근무지', '급료', '기간'],
      LOCAL_LIST: Object.freeze(LOCAL_LIST),
      JOB_TYPE_LIST: Object.freeze(JOB_TYPE_LIST),
      localList: '',
      jobTypeList: '',
    }
  },
  computed: {
    // localOnList() {
    //   return this.localList.filter(
    //     local => local.on === true && local.name !== '전체',
    //   )
    // },
    // jobTypeOnList() {
    //   return this.jobTypeList.filter(jobType => jobType.on === true)
    // },
  },
  methods: {
    //지역 / 직종 선택 change 이벤트
    localListChange() {
      //지역
      var localListSelect = document.getElementById('localListSelect')
      var localListText =
        localListSelect.options[localListSelect.selectedIndex].text
      if (localListText == '전체') localListText = ''
      //직종
      var jobtypeListSelect = document.getElementById('jobTypeListSelect')
      var jobtypeListValue =
        jobtypeListSelect.options[jobtypeListSelect.selectedIndex].value
      //   console.info('schRegion : ', localListText)
      //   console.info('schPosition : ', jobtypeListValue)
      const query = {
        schRegion: localListText,
        schPosition: jobtypeListValue,
      }
      this.$emit('search', query)
    },

    companyFilter() {
      // alert('a')
      var companyId = this.companyId
      const query = {
        schCompanyId: companyId,
        // schCompanyId: '전체',
        // schRegion: '경기',
      }
      this.$emit('filter', query)
    },
  },
  // updated: function () {
  //   this.companyFilter()
  // },
}
</script>

<style></style>
