import {
  getBannerList,
  getSpecialList,
  getUrgentList,
  getGeneralList,
} from 'Api/modules'
import { authCheck } from 'Utils/auth'
import { mapGetters, mapMutations } from 'vuex'
//import { sendMessageToApp } from 'Utils/message'
//import Cookies from 'js-cookie'

export default {
  data() {
    return {
      showFilter: false,
      schKeyword: '',
      schRegion: '', //지역
      schWorkPeriod: '', //근무기간 유형
      schBusiness: '', //숙박업 유형
      schPosition: '', //구인직종
      schPaymentMin: '',
      schPaymentMax: '',
      schCompanyId: '',
      schLat: this.latitude, //위도
      schLon: this.longitude, //경도

      bannerList: [], //{documentNo, jobPostTitle, closingTime, positionName, positionCnt, payAmt, workingAddress, workingTerm, emphasisCodes, recruitingStatus, photo, companyNm, closingDt, pushStatus}
      specialList: [],
      urgentList: [],
      generalList: [],

      loading: false,
      through: false,
      generalListPageNumber: 0,
      generalListNoMore: false,
      startSecondSearch: false,
    }
  },
  watch: {
    // '$route.query.schKeyword': {
    //   immediate: true,
    //   handler(newVal) {
    //     this.schKeyword = newVal
    //     this.fetchAllList()
    //   },
    // },

    '$route.query': {
      immediate: true,
      deep: true,
      handler() {
        this.setFromQuery()
        this.fetchAllList(false)
      },
    },
  },
  computed: {
    ...mapGetters(['isLogin', 'account', 'userId', 'latitude', 'longitude']),
  },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'SET_ACCOUNT_INFO']),
    setFromQuery() {
      const {
        schKeyword,
        schRegion,
        schWorkPeriod,
        schBusiness,
        schPosition,
        schPaymentMin,
        schPaymentMax,
        schCompanyId,
        schPushStatus,
      } = this.$route.query

      this.schKeyword = schKeyword
      this.schRegion = schRegion
      this.schWorkPeriod = schWorkPeriod
      this.schBusiness = schBusiness
      this.schPosition = schPosition
      this.schPaymentMin = schPaymentMin
      this.schPaymentMax = schPaymentMax
      this.schCompanyId = schCompanyId
      this.schPushStatus = schPushStatus
    },
    async fetchBannerList() {
      const res = await getBannerList({
        schKeyword: this.schKeyword, //this.schKeyword,
        schRegion: this.schRegion, //this.schRegion,
        schWorkPeriod: this.schWorkPeriod, // this.schWorkPeriod,
        schBusiness: this.schBusiness, //this.schBusiness,
        schPosition: this.schPosition, //this.schPosition,
        schPaymentMin: this.schPaymentMin, //this.schPayamtMin,
        schPaymentMax: this.schPaymentMax, //this.schPayamtMax,
        schCompanyId: this.schCompanyId,
        schPushStatus: this.schPushStatus,
        schLat: this.schLat,
        schLon: this.schLon,
        userId: this.userId || '',
      })
      this.bannerList = res.content
    },
    async fetchSpecialList(pageNumber = 0, pageSize = 4) {
      const res = await getSpecialList({
        schKeyword: this.schKeyword,
        schRegion: this.schRegion,
        schWorkPeriod: this.schWorkPeriod,
        schBusiness: this.schBusiness,
        schPosition: this.schPosition,
        schPaymentMin: this.schPaymentMin,
        schPaymentMax: this.schPaymentMax,
        schCompanyId: this.schCompanyId,
        schPushStatus: this.schPushStatus,
        distance: this.distance,
        schLat: this.schLat,
        schLon: this.schLon,
        userId: this.userId || '',
        pageNumber,
        pageSize,
      })
      this.specialList = res.content
    },
    async fetchUrgentList(pageNumber = 0, pageSize = 8) {
      const res = await getUrgentList({
        schKeyword: this.schKeyword,
        schRegion: this.schRegion,
        schWorkPeriod: this.schWorkPeriod,
        schBusiness: this.schBusiness,
        schPosition: this.schPosition,
        schPaymentMin: this.schPaymentMin,
        schPaymentMax: this.schPaymentMax,
        schCompanyId: this.schCompanyId,
        schPushStatus: this.schPushStatus,
        distance: this.distance,
        schLat: this.schLat,
        schLon: this.schLon,
        userId: this.userId || '',
        pageNumber,
        pageSize,
      })
      this.urgentList = res.content
    },
    async fetchGeneralList(pageNumber = 0, pageSize = 20) {
      this.loading = true
      const res = await getGeneralList({
        schKeyword: this.schKeyword,
        schRegion: this.schRegion,
        schWorkPeriod: this.schWorkPeriod,
        schBusiness: this.schBusiness,
        schPosition: this.schPosition,
        schPaymentMin: this.schPaymentMin,
        schPaymentMax: this.schPaymentMax,
        schCompanyId: this.schCompanyId,
        schPushStatus: this.schPushStatus,
        distance: this.distance,
        schLat: this.schLat,
        schLon: this.schLon,
        userId: this.userId || '',
        pageNumber,
        pageSize,
      })

      // 검색에 의해 리스트이 시작될때는 목록 초기화 필요함
      if (pageNumber === 0) {
        this.generalList = res.content
      } else {
        if (res.content.length === 0) this.generalListNoMore = true
        this.generalList.push(...res.content)
        this.loading = false
      }
    },
    async fetchAllList(setQuery = true) {
      // sendMessageToApp('LOG', {
      //   created: 'findjob.js',
      //   isApp: Cookies.get('isApp'),
      //   isLogin: Cookies.get('isLogin'),
      //   thisIsLogin: this.isLogin,
      //   userId: Cookies.get('userId'),
      //   thisUserId: this.userId,
      // })
      if (!this.userId) {
        const res = await authCheck()
        //vuex 세팅
        if (res) {
          this.$store.commit('SET_IS_LOGIN', true)
          this.$store.commit('SET_ACCOUNT_INFO', res)
        }
      }
      //console.log('this.latitude=', this.latitude)
      //console.log('this.longitude=', this.longitude)
      this.schLat = this.latitude
      this.schLon = this.longitude
      if (this.account.latitude) {
        this.schLat = this.account.latitude
        this.schLon = this.account.longitude
      }
      //로그인 후 위/경도 값 없는 경우
      if (this.userId && !this.account.latitude) {
        this.$confirm({
          message:
            '주소 정보가 없습니다.\n주소 정보를 입력하시면 근무처까지의 거리를 확인하실 수 있습니다.\n주소를 등록하시겠습니까?',
          button: {
            yes: '예',
            no: '오늘하루 보지 않기',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              location.href = '/account'
            } else {
              this.$cookies.set('ModalView', 'Y', '1d')
              this.isModalViewed = false
              this.$emit('ok')
            }
          },
        })
      }
      //console.log('this.account.latitude=', this.account.latitude)
      //console.log('this.account.longitude=', this.account.longitude)
      this.fetchBannerList()
      this.fetchSpecialList(0, 20)
      this.fetchUrgentList(0, 20)
      this.fetchGeneralList()

      if (setQuery) {
        const query = {
          schKeyword: this.schKeyword,
          schRegion: this.schRegion,
          schWorkPeriod: this.schWorkPeriod,
          schBusiness: this.schBusiness,
          schPosition: this.schPosition,
          schPaymentMin: this.schPaymentMin,
          schPaymentMax: this.schPaymentMax,
          schCompanyId: this.schCompanyId,
          schPushStatus: this.schPushStatus,
          schLat: this.schLat,
          schLon: this.schLon,
        }

        this.$router.push({
          name: 'findJob',
          query,
        })
      }
    },
    async onScroll({ scrollDiff, scrollTop }) {
      if (
        scrollTop < scrollDiff &&
        this.through &&
        !this.loading &&
        !this.generalListNoMore
      ) {
        this.startSecondSearch = true
        this.through = false
      }

      if (scrollTop >= scrollDiff && !this.through) {
        this.through = true
        this.fetched = false
        this.generalListPageNumber++
        this.fetchGeneralList(this.generalListPageNumber)
      }
    },
  },
  created() {
    this.$eventBus.$on('container-scroll', this.onScroll)
  },
  beforeDestroy() {
    this.$eventBus.$off('container-scroll', this.onScroll)
  },
}
